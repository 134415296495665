<template>
  <div class="ag-grid-filters">
    <div v-for="(filter_value, filter_key, filter_index) in filters" :key="filter_index" class="filter">
      <div class="filter_item simple_array" v-if="filter_value.type === 'simple_array'">
        <div class="heading">{{ filter_value.label }}:</div>
        <div class="variants">
          <b-form-checkbox-group
            class="filter-simple-array"
            v-model="filter_value.selected"
            :options="filter_value.options"
            switches
            @input="changeFilter(filter_value)"
          ></b-form-checkbox-group>
        </div>
      </div>
      <div class="filter_item conditions" v-if="filter_value.type === 'conditions'">
        <div class="heading">{{ filter_value.label }}:</div>
        <div class="variants">
          <b-form-checkbox-group
            v-model="filter_value.selected"
            :options="filter_value.options"
            switches
            @input="changeFilter(filter_value)"
          ></b-form-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'base-filters',
  components: {},
  data() {
    return {
      gridApi: null
    }
  },
  props: ['filters'],
  mounted() {
    this.$emitter.on('grid_api', (api) => {
      this.gridApi = api
      if (this.filters.length > 0) {
        for (const filter_value of this.filters) {
          this.changeFilter(filter_value)
        }
      }
    })
    this.$emitter.on('header_reset_filters', (api) => {
      if (this.filters.length > 0) {
        for (const filter_key in this.filters) {
          this.filters[filter_key]['selected'] = []
        }
      }
    })
  },
  methods: {
    async changeFilter(filter_value) {
      console.log(filter_value)
      if (filter_value.type === 'simple_array') {
        if (filter_value.selected.length > 0) {
          await this.gridApi.setColumnFilterModel(filter_value.field, {
            filterType: 'set',
            values: filter_value.selected
          })
          this.gridApi.onFilterChanged()
        } else {
          await this.gridApi.setColumnFilterModel(filter_value.field, {
            filterType: 'set',
            values: []
          })
          this.gridApi.onFilterChanged()
        }
      } else if (filter_value.type === 'conditions') {
        const values = filter_value.selected
        for (const condition of filter_value.conditions) {
          if (values.find((v) => v === condition.name)) {
            for (const columnKey in condition.columns) {
              await this.gridApi.setColumnFilterModel(columnKey, {
                filterType: 'set',
                values: condition.columns[columnKey]
              })
            }
          } else {
            for (const columnKey in condition.columns) {
              await this.gridApi.setColumnFilterModel(columnKey, {
                filterType: 'set',
                values: []
              })
            }
          }
        }
        this.gridApi.onFilterChanged()
      }
    }
  }
}
</script>
