<template>
  <div>
    <div class="progress-bar-container">
      <div class="progress-bar" :style="{ width: profit + '%' }" :class="progressBarClass">
        <span class="progress-label">{{ profitString }}%</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      profit: 0
    }
  },
  mounted() {
    this.profit = this.params.value
  },
  computed: {
    profitString() {
      return this.profit.toLocaleString('en', { maximumFractionDigits: 2 });
    },
    progressBarClass() {
      if (this.profit >= 91) {
        return 'green'
      } else if (this.profit >= 81 && this.profit < 91) {
        return 'light-green'
      } else if (this.profit >= 71 && this.profit < 81) {
        return 'lighter-green'
      } else if (this.profit >= 61 && this.profit < 71) {
        return 'pale-green'
      } else if (this.profit >= 51 && this.profit < 61) {
        return 'yellow'
      } else {
        return 'red'
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar-container {
  background-color: #e0e0e0;
  overflow: hidden;
  height: calc(100% + 2px);
  position: absolute !important;
  top: -1px;
  left: -1px;
  width: calc(100% + 1px);
}
.progress-bar {
  height: calc(100% + 2px);
  display: flex;
  align-items: baseline;
  padding: 0 10px;
  color: white;
  font-size: 14px;
  transition: width 0.4s ease;
  min-width: 35%;
}
.progress-label {
  z-index: 2;
}
.green {
  background-color: #0D9201;
}
.light-green {
  background-color: #30CB00;
}
.lighter-green {
  background-color: #49E54A;
}
.pale-green {
  background-color: #B6E7B2;
}
.yellow {
  background-color: #FFCC17;
}
.red {
  background-color: #FF3D34;
}
</style>
