<template>
  <div class="charts mb-3" style="height: 800px">
    <div v-if="data && data.activityTags && data.activityTags.length > 0">
      <ag-charts-vue :options="options"></ag-charts-vue>
      <div class="buttons-group">
        <button @click="toggleMode" class="button-outline-blue switch">
          {{ mode === 'dollars' ? 'Switch to Hours' : 'Switch to Dollars' }}
        </button>
        <button @click="toggleAll(false)" class="button-outline-blue switch">
          Disable All
        </button>
        <button @click="toggleAll(true)" class="button-outline-blue switch">
          Enable All
        </button>
      </div>
      <div class="custom-legend">
        <div
          class="legend-item"
          v-for="(activityTag, index) in data.activityTags"
          :key="index"
          @click="toggleActive(index)"
          :class="{ active: activityTag.active, inactive: !activityTag.active }"
        >
          <input
            type="checkbox"
            :checked="activityTag.active"
            @change="toggleActive(index)"
            class="hidden-checkbox"
          />
          <div class="color" :style="{ backgroundColor: activityTag.color }"></div>
          <div class="label">{{ activityTag.label }} ({{ priceFormatter(activityTag.total) }})</div>
        </div>
      </div>
    </div>
    <b-skeleton height="100%" v-else></b-skeleton>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  name: 'projects-charts-exp',
  data() {
    return {
      options: null,
      areSeriesVisible: true,
      mode: null
    }
  },
  props: ['data'],
  components: {
    AgChartsVue
  },
  watch: {
    data() {
      this.setOptions()
    }
  },
  created() {
    this.mode = this.$store.getters.getExpChartsMode
    this.setOptions()
  },
  methods: {
    priceFormatter(total) {
      if (this.mode === 'dollars') {
        return SimpleHelper.priceFormatter(total)
      } else {
        return `${total.toFixed(1)}h`
      }
    },
    setOptions() {
      const series = []
      if (!this.data) {
        return false
      }
      this.data.activityTags = this?.data?.activityTags?.length > 0 ? this.data.activityTags.map((at) => {
        let total = null
        if (this.mode === 'dollars') {
          total = this.data.map.reduce((acc, obj) => {
            let value = Number(obj[at.name])
            return isNaN(value) ? acc : acc + value
          }, 0)
        } else {
          total = this.data.mapHours.reduce((acc, obj) => {
            let value = Number(obj[at.name])
            return isNaN(value) ? acc : acc + value
          }, 0)
        }
        return {
          ...at,
          active: this.$store.getters.getExpChartTagsVisible(at.name),
          total
        }
      }) : []
      const activeTags = this?.data?.activityTags?.length > 0 ? this.data.activityTags.filter((at) => at.active) : []

      if (this.data && activeTags.length > 0) {
        for (const activityTag of activeTags) {
          const mode = this.mode
          series.push({
            type: 'bar',
            xKey: 'month',
            stacked: true,
            yKey: activityTag.name,
            yName: activityTag.label,
            fill: activityTag.color,
            stroke: activityTag.color,
            tooltip: {
              renderer: function ({ datum, xKey, yKey }) {
                return {
                  content: `${SimpleHelper.monthYearStringToLabels(datum[xKey])}: ${mode === 'dollars' ? SimpleHelper.priceFormatter(datum[yKey]) : datum[yKey] + 'h'}`,
                  title: yKey.replaceAll('_', ' ')
                }
              }
            }
          })
        }
        this.options = {
          height: 600,
          data: this.mode === 'dollars' ? this.data.map : this.data.mapHours,
          series: series,
          legend: {
            enabled: false
          },
          axes: [
            {
              type: 'category',
              position: 'bottom',
              label: {
                formatter: (params) => {
                  return SimpleHelper.monthYearStringToLabels(params.value)
                }
              }
            },
            {
              type: 'number',
              position: 'left'
            }
          ]
        }
      } else {
        this.options = JSON.parse(JSON.stringify({
          height: 600,
          data: [],
          series: []
        }))
      }
    },
    toggleMode() {
      this.mode = this.mode === 'dollars' ? 'hours' : 'dollars'
      this.$store.commit('setExpChartsMode', { value: this.mode })
      this.setOptions()
    },
    toggleActive(index) {
      this.$store.commit('setExpChartTagsVisible', { key: this.data.activityTags[index].name, value: !this.data.activityTags[index].active });
      this.$set(this.data.activityTags, index, {
        ...this.data.activityTags[index],
        active: !this.data.activityTags[index].active
      })
      this.setOptions()
    },
    toggleAll(active) {
      this.data.activityTags = this.data.activityTags.map((at) => {
        this.$store.commit('setExpChartTagsVisible', { key: at.name, value: active });
        return {
          ...at,
          active
        }
      })
      this.setOptions()
    }
  }
}
</script>

<style lang="scss" scoped>
.b-skeleton {
  border-radius: 0;
}
.charts {
  border: 1px solid #e6e6e6;
}
.buttons-group {
  display: flex;
  margin: 0px 25px 10px;
  gap: 10px;
}
.custom-legend {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0 20px 20px 20px;
}
.legend-item {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 5px;
}
.legend-item .label {
  font-size: 0.8rem;
}
.legend-item:hover {
  background-color: #f0f0f0;
}
.legend-item.active {
  opacity: 1;
}
.legend-item.inactive {
  opacity: 0.2;
}
.legend-item .color {
  display: block;
  height: 20px;
  width: 20px;
}
.hidden-checkbox {
  display: none;
}
</style>
