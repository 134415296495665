<template>
  <div class="projects-page">
    <div class="agGrid-page container">
      <base-header>
        <header-projects-charts-addon />
        <date-picker-addon />
        <show-archived-addon />
        <refresh-analytics type="projects" />
      </base-header>
      <projects-charts-pft :data="chartsPftData" v-show="showChartsPft" />
      <projects-charts-exp :data="chartsExpData" v-show="showChartsExp" />
      <projects-charts-efc :data="chartsEfcData" v-show="showChartsEfc" />

      <div id="filterCheckboxes" class="mb-3">
        <b-form-checkbox-group
          class="filter-simple-array"
          v-model="selectedFilters"
          :options="filters"
          switches
          @input="applyTypeFilter"
        ></b-form-checkbox-group>
      </div>
      
      <div class="ag-grid-container">
        <div class="ag-table">
          <ag-grid-vue
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
            headerHeight="32"
            rowHeight="32"
            :rowData="rowData"
            :columnDefs="columnDefs"
            :statusBar="statusBar"
            @grid-ready="onGridReady"
            @filter-changed="stateEvents"
            @column-resized="stateEvents"
            @sort-changed="stateEvents"
            @column-visible="stateEvents"
            @column-pivot-changed="stateEvents"
            @column-row-group-changed="stateEvents"
            @column-value-changed="stateEvents"
            @column-moved="stateEvents"
            @column-pinned="stateEvents"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Init components
import moment from 'moment';
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import DatePickerAddon from '@/agGridV2/components/header/date-picker.component.vue'
import HeaderProjectsChartsAddon from '@/agGridV2/components/header/projects-charts.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import ShowArchivedAddon from '@/agGridV2/components/header/show-archived.component.vue'
import RefreshAnalytics from '@/agGridV2/components/header/refresh-analytics.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import { mapGetters, mapMutations } from 'vuex'
// Custom Cell Renderer
import ProfitRenderer from '@/agGridV2/renderers/profit.renderer.vue'
import ProjectsChartsExp from '@/agGridV2/components/projects-charts-exp.component.vue'
import ProjectsChartsEfc from '@/agGridV2/components/projects-charts-efc.component.vue'
import ProjectsChartsPft from '@/agGridV2/components/projects-charts-pft.component.vue'
import { isNumber } from 'lodash';

export default {
  components: {
    BaseHeader,
    AgGridVue,
    DatePickerAddon,
    ShowArchivedAddon,
    HeaderProjectsChartsAddon,
    // eslint-disable-next-line vue/no-unused-components
    ProfitRenderer,
    ProjectsChartsExp,
    ProjectsChartsEfc,
    ProjectsChartsPft,
    RefreshAnalytics
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      gridApi: null,
      activityTags: null,
      uniqueActivityTags: null,
      defaultColDef: {
        cellClassRules: {
          'not-editable': (params) => {
            return params.value === 'inapplicable';
          },
        }
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' }
        ]
      },
      sideBar: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                minWidth: 225,
                maxWidth: 225,
                width: 225,
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  closeToolPanel: true
                }
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                minWidth: 180,
                maxWidth: 400,
                width: 250,
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  closeToolPanel: true
                }
            }
        ],
        position: 'right'
      },
      chartsExpData: null,
      chartsPftData: null,
      chartsEfcData: null,
      filters: ['SEO', 'PPC', 'DEV', 'MNT', 'DEV-INT', 'PPC-INT', 'OTH-INT', 'SEO-INT'],
      selectedFilters: ['SEO', 'PPC', 'DEV', 'MNT']
    }
  },
  methods: {
    ...mapMutations(['setViewLoader']),
    ...mapGetters([
      'getShowChartsPft',
      'getShowChartsExp',
      'getShowChartsEfc'
    ]),
    calculateFieldSum(statistics, startMonth, endMonth, fieldPath) {
      const [startMonthNum, startYear] = startMonth.split('_').map(Number);
      const [endMonthNum, endYear] = endMonth.split('_').map(Number);
      let sum = 0;

      for (let i = statistics.length - 1; i >= 0; i--) { // Идем с конца массива
        const [month, year] = statistics[i].month.split('_').map(Number);
        if (
          (year > startYear || (year === startYear && month >= startMonthNum)) &&
          (year < endYear || (year === endYear && month <= endMonthNum))
        ) {
          const value = this.getNestedValue(statistics[i], fieldPath);
          sum += value || 0;
        } else {
          statistics.splice(i, 1);
        }
      }

      return sum;
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, key) => {
        return acc && acc[key] !== undefined ? acc[key] : undefined;
      }, obj);
    },
    getActiveTags(statistics) {
      const activeTags = new Set();
      statistics.forEach(stat => {
        const tags = stat.activity_tags;
        if (tags) {
          Object.keys(tags).forEach(tag => activeTags.add(tag));
        }
      })
      return Array.from(activeTags);
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    dateCodeSplitter(code) {
      const [ year, month ] = code.split('/');
      return `${month}_${year}`;
    },
    validateNumber(value) {
      return isFinite(value) && value >= 0 ? value : 0;
    },
    updateRowData() {
      this.rowData = Object.freeze(
        this.copyRowData(this.calculateRowData(this.rowData))
      );
    },
    updateCharts() {
      const displayedRowCount = this.gridApi.getDisplayedRowCount();
      let filteredData = [];
      for (let i = 0; i < displayedRowCount; i++) {
        const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
        filteredData.push(rowNode.data);
      }
      if (filteredData.length === 0) {
        filteredData = this.rowData;
      }

      const map = {};
      const map_pft = {};
      const map_efc = {};
      const map_hours = {};
      const activity_tags = {};

      for (const project of filteredData) {
        for (const statistic of project.statistic_for_charts) {
          if (!map[statistic.month]) {
            map[statistic.month] = {
              month: statistic.month
            };
            map_pft[statistic.month] = {
              month: statistic.month
            };
            map_efc[statistic.month] = {
              month: statistic.month
            };
          }
          if (!map_hours[statistic.month]) {
            map_hours[statistic.month] = {
              month: statistic.month
            };
          }
          if (!map_pft[statistic.month]['total_revenue']) {
            map_pft[statistic.month]['total_revenue'] = 0;
          }
          if (!map_efc[statistic.month]['total_revenue']) {
            map_efc[statistic.month]['total_revenue'] = 0;
          }
          if (!map_pft[statistic.month]['total_expenses']) {
            map_pft[statistic.month]['total_expenses'] = 0;
          }
          if (!map_pft[statistic.month]['active_projects']) {
            map_pft[statistic.month]['active_projects'] = 0;
          }
          if (!map_pft[statistic.month]['active_projects_abbr_l']) {
            map_pft[statistic.month]['active_projects_abbr_l'] = [];
          }
          if (statistic.active) {
            map_pft[statistic.month]['active_projects']++;
            map_pft[statistic.month]['active_projects_abbr_l'].push(
              `${project.abbr_l}-${project.type}`,
            );
          }
          if (statistic.paid_invoices_amount) {
            map_pft[statistic.month]['total_revenue'] += statistic.paid_invoices_amount;
            map_efc[statistic.month]['total_revenue'] += statistic.paid_invoices_amount;
          }
          if (statistic.expenses_amount) {
            map_pft[statistic.month]['total_expenses'] += statistic.expenses_amount;
          }
          if (statistic.activity_tags) {
            Object.entries(statistic.activity_tags).forEach(([key, value]) => {
              if (!map[statistic.month][key]) {
                map[statistic.month][key] = 0;
              }
              if (!map_efc[statistic.month][key]) {
                map_efc[statistic.month][key] = 0;
              }
              if (key.includes('external')) {
                map[statistic.month][key] += value.expenses_amount_external;
                map_efc[statistic.month][key] += value.expenses_agency_hours_external;
              } else {
                map[statistic.month][key] += value.expenses_amount;
                map_efc[statistic.month][key] += value.expenses_agency_hours;
              }
              if (!map_hours[statistic.month][key]) {
                map_hours[statistic.month][key] = 0;
              }
              map_hours[statistic.month][key] += value.expenses_hours;
              if (!activity_tags[key]) {
                let color = null;
                const activityTagObject = this.activityTags.find((at) => at.name === this.activityTagHeaderFormatter(key).toLowerCase());
                if (activityTagObject?.color || activityTagObject?.parent_tag) {
                  if (!activityTagObject.color) {
                    color = this.activityTags.find((at) => at._id === activityTagObject.parent_tag).color;
                  } else {
                    color = activityTagObject.color;
                  }
                }
                activity_tags[key] = {
                  name: key,
                  label: key,
                  color
                };
              }
            });
          }
        }
      }

      const numberFixed = (object) => {
        for (const map_key in object) {
          const map_object = object[map_key];
          for (const map_object_key in map_object) {
            if (map_object_key === 'month') { continue; }
            if (!isNumber(map_object[map_object_key])) { continue; }
            object[map_key][map_object_key] = Number(object[map_key][map_object_key].toFixed(2));
          }
        }
      }

      numberFixed(map);
      numberFixed(map_hours);

      const newActivityTags = {};
      this.uniqueActivityTags.sort();
      for (const uniqueActivityTagKey of this.uniqueActivityTags) {
        const object = this.activityTags.find((at) => at.name === this.activityTagHeaderFormatter(uniqueActivityTagKey).toLowerCase());
        const objectExist = activity_tags[uniqueActivityTagKey];
        if (objectExist && object) {
          newActivityTags[uniqueActivityTagKey] = {
            color: objectExist.color,
            name: objectExist.label,
            label: object.name
          }
        }
      }

      const sortByMonthYear = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).sort(([keyA], [keyB]) => {
            const [monthA, yearA] = keyA.split('_').map(Number);
            const [monthB, yearB] = keyB.split('_').map(Number);
            return yearA === yearB ? monthA - monthB : yearA - yearB;
          })
        );
      };

      this.chartsExpData = JSON.parse(JSON.stringify({
        activityTags: Object.values(newActivityTags),
        map: Object.values(sortByMonthYear(map)),
        mapHours: Object.values(sortByMonthYear(map_hours))
      }));

      for (const map_pft_key in map_pft) {
        const object = map_pft[map_pft_key];
        let profit = 0;
        if (object.total_revenue > 0) {
          profit = ((object.total_revenue - object.total_expenses) / object.total_revenue) * 100;
          if (!isFinite(profit)) {
            profit = 0;
          }
        }
        map_pft[map_pft_key]['average_profitability'] = profit;
      }

      for (const map_efc_key in map_efc) {
        const object = map_efc[map_efc_key];
        let total_revenue = 0;
        if (object.total_revenue > 0) {
          total_revenue = object.total_revenue / 100;
        }
        map_efc[map_efc_key]['total_revenue'] = total_revenue;
      }

      numberFixed(map_efc);
      numberFixed(map_pft);

      this.chartsEfcData = JSON.parse(JSON.stringify({
        activityTags: Object.values(newActivityTags),
        map: Object.values(sortByMonthYear(map_efc))
      }));

      this.chartsPftData = JSON.parse(JSON.stringify({
        activityTags: [
          { name: 'total_revenue', label: 'Total Revenue', color: '#4e92d9' },
          { name: 'total_expenses', label: 'Total Expenses', color: '#ff9e3c' },
          { name: 'average_profitability', label: 'Average Profitability', color: '#469b57' },
          { name: 'active_projects', label: 'Active Projects', color: '#35bee2' },
        ],
        map: Object.values(sortByMonthYear(map_pft))
      }));
    },
    inapplicableComparator(valueA, valueB) {
      const order = ['', undefined, null]; // Порядок для пустых значений
      const inapplicable = 'inapplicable';
      const isValueAEmpty = order.includes(valueA);
      const isValueBEmpty = order.includes(valueB);
      if (isValueAEmpty && isValueBEmpty) {
        return 0;
      }
      if (isValueAEmpty) {
        return -1;
      }
      if (isValueBEmpty) {
        return 1;
      }
      if (valueA === inapplicable && valueB === inapplicable) {
        return 0;
      }
      if (valueA === inapplicable) {
        return 1;
      }
      if (valueB === inapplicable) {
        return -1;
      }
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      }
      return 0;
    },
    activityTagHeaderFormatter(activityTag) {
      return activityTag.replaceAll('_', ' ').split(' ').join(' ').toUpperCase();
    },
    analyticsSort(analytics) {
      const statusOrder = ['active', 'archived'];
      const typeOrder = ['SEO', 'PPC', 'DEV', 'MNT', '-INT'];
      const combinedComparator = (a, b) => {
        // Status sort
        const statusIndexA = statusOrder.indexOf(a.status);
        const statusIndexB = statusOrder.indexOf(b.status);
        if (statusIndexA !== -1 && statusIndexB !== -1) {
          if (statusIndexA !== statusIndexB) {
            return statusIndexA - statusIndexB;
          }
        } else if (statusIndexA !== -1) {
          return -1;
        } else if (statusIndexB !== -1) {
          return 1;
        }
        // Type Sort
        const typeIndexA = typeOrder.indexOf(a.type);
        const typeIndexB = typeOrder.indexOf(b.type);
        if (typeIndexA !== -1 && typeIndexB !== -1) {
          if (typeIndexA !== typeIndexB) {
            return typeIndexA - typeIndexB;
          }
        } else if (typeIndexA !== -1) {
          return -1;
        } else if (typeIndexB !== -1) {
          return 1;
        }
        // Date sort
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return dateB - dateA;
      };
      return analytics.sort(combinedComparator);
    },
    setState() {
      const state = this.$store.getters.getAgGridState(this.$route.name)
      if (state) {
        this.gridApi.applyColumnState({ state: JSON.parse(state), applyOrder: true })
      }
      const stateFilter = this.$store.getters.getAgGridFilterChanged(this.$route.name)
      if (stateFilter) {
        this.gridApi.setFilterModel(JSON.parse(stateFilter))
      }
    },
    stateEvents(event) {
      if (event.type === 'filterChanged') {
        this.$store.dispatch('setAgGridFilterChanged', { page: this.$route.name, query: JSON.stringify(this.gridApi.getFilterModel()) })
      } else {
        this.$store.dispatch('setAgGridState', { page: this.$route.name, query: JSON.stringify(this.gridApi.getColumnState()) })
      }
      this.$nextTick(() => {
        this.updateCharts();
      });
    },
    copyRowData(data) {
      return data.map(datum => {
          return {
              ...datum
          }
      })
    },
    calculateRowData(data) {
      const startDate = this.dateCodeSplitter(this.$store.state.managementTools.selectedDateFrom.code);
      const endDate = this.dateCodeSplitter(this.$store.state.managementTools.selectedDateTo.code);

      const rowData = data.map((a) => {
        const object = JSON.parse(JSON.stringify(a));
        const statistic = a.statistic;
        const activityTags = this.getActiveTags(statistic);

        const paidInvoicesSum = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, 'paid_invoices_amount'));
        const unpaidInvoicesSum = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, 'unpaid_invoices_amount'));
        const expensesSum = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, 'expenses_amount'));
        const expensesSumExternal = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, 'expenses_amount_external'));
        const expensesHoursSum = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, 'expenses_hours'));
        const expensesAgencyHoursSum = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, 'expenses_agency_hours'));
        const expensesAgencyHoursSumExternal = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, 'expenses_agency_hours_external'));

        const totalByActivityTags = {};
        for (const activityTag of activityTags) {
          totalByActivityTags[`total_expenses_hours_by_${activityTag}`] = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, `activity_tags.${activityTag}.expenses_hours`));
          if (activityTag.includes('external')) {
            totalByActivityTags[`total_expenses_by_${activityTag}`] = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, `activity_tags.${activityTag}.expenses_amount_external`));
            totalByActivityTags[`total_expenses_agency_hours_by_${activityTag}`] = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, `activity_tags.${activityTag}.expenses_agency_hours_external`));
          } else {
            totalByActivityTags[`total_expenses_by_${activityTag}`] = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, `activity_tags.${activityTag}.expenses_amount`));
            totalByActivityTags[`total_expenses_agency_hours_by_${activityTag}`] = this.validateNumber(this.calculateFieldSum(statistic, startDate, endDate, `activity_tags.${activityTag}.expenses_agency_hours`));
          }
        }

        if (["MNT", "OTH-INT", "DEV-INT"].includes(object.type)) {
          object.platform_url = 'inapplicable';
        }
        if (["MNT", "PPC-INT", "SEO-INT", "OTH-INT", "DEV-INT"].includes(object.type)) {
          object.kick_off_date = 'inapplicable';
          object.cancellation_date = 'inapplicable';
        }
        if (["DEV"].includes(object.type)) {
          object.cancellation_date = 'inapplicable';
        } 
        if (object.type !== 'DEV') {
          object.budget = 'inapplicable';
          object.real_launch_date = 'inapplicable';
        }

        const returnObject = {
          ...object,
          statistic_for_charts: statistic,
          total_budget: object.budget,
          total_revenue: paidInvoicesSum,
          total_revenue_unpaid: unpaidInvoicesSum,
          total_expenses: expensesSum + expensesSumExternal,
          total_expenses_agency_hours: expensesAgencyHoursSum + expensesAgencyHoursSumExternal,
          total_expenses_hours: expensesHoursSum,
          total_expenses_agency_hours_internal: expensesAgencyHoursSum,
          total_expenses_external: expensesSumExternal,
          total_expenses_agency_hours_external: expensesAgencyHoursSumExternal,
          ...totalByActivityTags
        };

        let profit = 0;
        if (object.type !== 'DEV') {
          profit = ((returnObject.total_revenue - returnObject.total_expenses) / returnObject.total_revenue) * 100;
        } else {
          profit = ((returnObject.total_budget - returnObject.total_expenses) / returnObject.total_budget) * 100;
        }
        if (!isFinite(profit)) {
          profit = 0;
        }
        returnObject['total_profit'] = profit;

        let effectiveness = 0;
        if (object.type !== 'DEV') {
          effectiveness = (returnObject.total_revenue * 100) / (returnObject.total_expenses_agency_hours * 100);
        } else {
          effectiveness = (returnObject.total_budget * 100) / (returnObject.total_expenses_agency_hours * 100);
        }
        if (isNaN(effectiveness)) {
          effectiveness = 0;
        }
        if (!isFinite(effectiveness)) {
          effectiveness = 0;
        }

        returnObject['effectiveness'] = effectiveness;

        return returnObject;
      });

      return rowData;
    },
    generateColumnDefs(analytics) {
      // Get unique tags
      const uniqueActivityTags = new Set();
      for (const project of analytics) {
        project.statistic.forEach(stat => {
          const tags = stat.activity_tags;
          if (tags) {
            Object.keys(tags).forEach(tag => uniqueActivityTags.add(tag));
          }
        });
      }
      const uniqueActivityTagsArray = Array.from(uniqueActivityTags);
      uniqueActivityTagsArray.sort();
      this.uniqueActivityTags = uniqueActivityTagsArray;
      // Generate colums

      const total_expenses = [
        {
          field: 'total_expenses',
          headerName: 'Total ($)',
          valueFormatter: params => { return SimpleHelper.priceFormatter(params.value) },
          filter: 'agNumberColumnFilter'
        },
        {
          field: 'total_expenses_agency_hours',
          headerName: 'Total (ah)',
          valueFormatter: params => { return params.value ? SimpleHelper.numberFormatter(params.value) + 'ah' : '' },
          filter: 'agNumberColumnFilter'
        },
        {
          field: 'total_expenses_hours',
          headerName: 'Total Internal (h)',
          valueFormatter: params => { return params.value ? SimpleHelper.numberFormatter(params.value) + 'h' : '' },
          filter: 'agNumberColumnFilter'
        },
        {
          field: 'total_expenses_agency_hours_internal',
          headerName: 'Total Internal (ah)',
          valueFormatter: params => { return params.value ? SimpleHelper.numberFormatter(params.value) + 'ah' : '' },
          filter: 'agNumberColumnFilter'
        },
        {
          field: 'total_expenses_external',
          headerName: 'Total External ($)',
          valueFormatter: params => { return params.value ? SimpleHelper.numberFormatter(params.value) + '$' : '' },
          filter: 'agNumberColumnFilter'
        },
        {
          field: 'total_expenses_agency_hours_external',
          headerName: 'Total External (ah)',
          valueFormatter: params => { return params.value ? SimpleHelper.numberFormatter(params.value) + 'ah' : '' },
          filter: 'agNumberColumnFilter'
        }
      ];
      for (const activityTag of uniqueActivityTagsArray) {
        total_expenses.push({
          field: `total_expenses_by_${activityTag}`,
          headerName: this.activityTagHeaderFormatter(activityTag) + ' ($)',
          columnGroupShow: 'open',
          valueFormatter: params => { return SimpleHelper.priceFormatter(params.value) },
          filter: 'agNumberColumnFilter'
        });
        total_expenses.push({
          field: `total_expenses_hours_by_${activityTag}`,
          headerName: this.activityTagHeaderFormatter(activityTag) + ' (H)',
          columnGroupShow: 'open',
          valueFormatter: params => { return params.value ? SimpleHelper.numberFormatter(params.value) + 'h' : '' },
          filter: 'agNumberColumnFilter'
        });
        total_expenses.push({
          field: `total_expenses_agency_hours_by_${activityTag}`,
          headerName: this.activityTagHeaderFormatter(activityTag) + ' (ah)',
          columnGroupShow: 'open',
          valueFormatter: params => { return params.value ? SimpleHelper.numberFormatter(params.value) + 'ah' : '' },
          filter: 'agNumberColumnFilter'
        });
      }

      return [
        {
          headerName: 'Project',
          filter: 'agSetColumnFilter',
          cellRenderer: function (params) {
            if (!params.value) return ''
            const status = params.data.status
            let intClass = ' '
            if (params.data.type.includes('-INT')) {
              intClass = ' project_int '
            }
            let html = `<div class="project_status${intClass}project_status--${status}">${params.value}</div>`
            if (params.data.gocardless) {
              html += '<div class="gocardless"></div>'
            }
            return html
          },
          valueGetter: params => {
            return params.data.abbr_l + '-' + params.data.type
          }
        },
        {
          field: 'cms',
          headerName: 'CMS',
          filter: 'agSetColumnFilter'
        },
        {
          field: 'platform_url',
          headerName: 'Platform',
          filter: 'agSetColumnFilter',
          comparator: this.inapplicableComparator,
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          },
        },
        {
          field: 'type',
          headerName: 'Type',
          filter: 'agSetColumnFilter'
        },
        {
          field: 'client_name',
          headerName: 'Client Name (QB)',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Industry',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => params.data.industry ? SimpleHelper.getIndustryLabel(params.data.industry) : 'Not set'
        },
        {
          field: 'total_profit',
          headerName: 'Profitability',
          cellRenderer: 'ProfitRenderer',
          filter: 'agNumberColumnFilter'
        },
        {
          field: 'effectiveness',
          headerName: 'Effectiveness',
          cellRenderer: 'ProfitRenderer',
          filter: 'agNumberColumnFilter'
        },
        {
          field: 'total_revenue',
          headerName: 'Total Revenue',
          filter: 'agNumberColumnFilter',
          cellRenderer: (params) => {
            let string = SimpleHelper.priceFormatter(params.value)
            if (params.data.total_revenue_unpaid) {
              string += '<div style="color: red; display: inline"> (' + SimpleHelper.priceFormatter(params.data.total_revenue_unpaid) + ')</div>'
            }
            return string
          }
        },
        {
          field: 'total_budget',
          headerName: 'Total Budget',
          valueFormatter: params => { return SimpleHelper.priceFormatter(params.value) },
          comparator: this.inapplicableComparator,
          filter: 'agNumberColumnFilter',
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          },
        },
        {
          children: total_expenses,
          headerName: 'Total Expenses'
        },
        {
          field: 'kick_off_date',
          headerName: 'Kick off Date',
          valueGetter: params => {
            if (!params.data.kick_off_date) {
              return '';
            }
            if (params.data.kick_off_date === 'inapplicable') {
              return 'inapplicable';
            }
            return new Date(params.data.kick_off_date);
          },
          valueFormatter: params => { return SimpleHelper.dateFormatter(params.value) },
          comparator: this.inapplicableComparator,
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          },
          filter: 'agDateColumnFilter'
        },
        {
          field: 'real_launch_date',
          headerName: 'Launch Date',
          valueGetter: params => {
            if (!params.data.real_launch_date) {
              return '';
            }
            if (params.data.real_launch_date === 'inapplicable') {
              return 'inapplicable';
            }
            return new Date(params.data.real_launch_date);
          },
          valueFormatter: params => { return SimpleHelper.dateFormatter(params.value) },
          comparator: this.inapplicableComparator,
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          },
          filter: 'agDateColumnFilter'
        },
        {
          field: 'cancellation_date',
          headerName: 'Cancellation Date',
          valueGetter: params => {
            if (!params.data.cancellation_date) {
              return '';
            }
            if (params.data.cancellation_date === 'inapplicable') {
              return 'inapplicable';
            }
            return new Date(params.data.cancellation_date);
          },
          valueFormatter: params => { return SimpleHelper.dateFormatter(params.value) },
          comparator: this.inapplicableComparator,
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          },
          filter: 'agDateColumnFilter'
        },
        { 
          field: 'status', 
          filter: 'agTextColumnFilter',
          hide: true
        },
        { 
          field: 'team.am', 
          headerName: 'AM',
          filter: 'agSetColumnFilter'
        },
        { 
          field: 'team.am_supervisor', 
          headerName: 'AM supervisor',
          filter: 'agSetColumnFilter'
        },
        { 
          field: 'team.pm', 
          headerName: 'PM',
          filter: 'agSetColumnFilter'
        },
        { 
          field: 'team.ae', 
          headerName: 'AE',
          filter: 'agSetColumnFilter'
        }
      ];
    },
    showArchived() {
      let currentFilterModel = this.gridApi.getFilterModel();
      if (this.$store.getters.getShowArchived(this.$route.name)) {
        delete currentFilterModel.status;
      } else {
        currentFilterModel.status = {
          filterType: 'text',
          type: 'equals',
          filter: 'active'
        };
      }
      this.gridApi.setFilterModel(currentFilterModel);
    },
    applyTypeFilter() {
      const selectedValues = Array.from(document.querySelectorAll('#filterCheckboxes input:checked')).map(checkbox => checkbox.value);
      this.gridApi.getColumnFilterInstance('type').then(filterInstance => {
        if (filterInstance) {
            filterInstance.setModel({ values: selectedValues });
            this.gridApi.onFilterChanged();
        }
      });
    }
  },
  computed: {
    showChartsPft() {
      return this.getShowChartsPft()
    },
    showChartsExp() {
      return this.getShowChartsExp()
    },
    showChartsEfc() {
      return this.getShowChartsEfc()
    }
  },
  beforeMount() {
    this.$emitter.on('header_search_addon_changed', () => {
      this.gridApi.setGridOption("quickFilterText", this.$store.getters.getSearchQuery(this.$route.name));
    });
    this.$emitter.on('header_date_picker_addon_changed', () => {
      this.updateRowData();
      this.$nextTick(() => {
        this.updateCharts();
      });
    });
    this.$emitter.on('header_show_archived_addon_changed', () => {
      this.showArchived();
      this.$nextTick(() => {
        this.updateCharts();
      });
    });
    this.$emitter.on('header_reset_state', () => {
      this.gridApi.resetColumnState()
      const timeout = setTimeout(() => {
        this.$store.dispatch('setAgGridState', { page: this.$route.name, query: '' })
        clearTimeout(timeout)
      }, 10)
    });
    this.$emitter.on('header_reset_filters', () => {
      if (this.gridApi && this.gridApi.destroyCalled === false) {
        this.gridApi.setFilterModel(null);
        this.gridApi.setGridOption("quickFilterText", null);
        this.selectedFilters = ['SEO', 'PPC', 'DEV', 'MNT'];
        this.$nextTick(() => {
          this.applyTypeFilter();
          this.updateCharts();
        });
        const timeout = setTimeout(() => {
          this.$store.dispatch('setAgGridFilterChanged', { page: this.$route.name, query: '' });
          clearTimeout(timeout);
        }, 10);
      }
    });
  },
  mounted() {
    (async() => {
      this.setViewLoader(true);
      const [analytics, activityTags] = await Promise.all([
        MtApi.agGridProjectsAnalyticsV2(),
        MtApi.getActivityTags()
      ]);
      this.activityTags = activityTags;
      this.columnDefs = Object.freeze(
        this.generateColumnDefs(analytics.data)
      );
      this.rowData = Object.freeze(
        this.copyRowData(this.calculateRowData(this.analyticsSort(analytics.data)))
      );
      this.$nextTick(() => {
        this.gridApi.setGridOption("quickFilterText", this.$store.getters.getSearchQuery(this.$route.name));
        this.setState();
        this.updateCharts();
        this.showArchived();
        this.applyTypeFilter();
        this.setViewLoader(false);
      });
    })();
  }
}
</script>

<style lang="scss">
.custom-control-label {
  display: flex;
  align-items: center
}
</style>