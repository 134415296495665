<template>
  <div class="charts mb-3" style="height: 700px">
    <div v-if="data && data.activityTags && data.activityTags.length > 0">
      <ag-charts-vue :options="options"></ag-charts-vue>
      <div class="buttons-group">
        <button @click="toggleAll(false)" class="button-outline-blue switch">
          Disable All
        </button>
        <button @click="toggleAll(true)" class="button-outline-blue switch">
          Enable All
        </button>
      </div>
      <div class="custom-legend">
        <div
          class="legend-item"
          v-for="(activityTag, index) in data.activityTags"
          :key="index"
          @click="toggleActive(index)"
          :class="{ active: activityTag.active, inactive: !activityTag.active }"
        >
          <input
            type="checkbox"
            :checked="activityTag.active"
            @change="toggleActive(index)"
            class="hidden-checkbox"
          />
          <div class="color" :style="{ backgroundColor: activityTag.color }"></div>
          <div class="label">{{ activityTag.label }}{{ totalFormatter(activityTag.total, activityTag.name) }}</div>
        </div>
      </div>
    </div>
    <b-skeleton height="100%" v-else></b-skeleton>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  name: 'projects-charts-pft',
  data() {
    return {
      options: null
    }
  },
  props: ['data'],
  components: {
    AgChartsVue
  },
  watch: {
    data() {
      this.setOptions()
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    totalFormatter(total, name) {
      if (name === 'average_profitability') {
        return ` (${(total / this.data.map.length).toFixed(0)}%)`
      } else if (name === 'active_projects') {
        return ''
      } else {
        return ` (${SimpleHelper.priceFormatter(total)})`
      }
    },
    setOptions() {
      const series = []
      if (!this.data) {
        return false
      }
      this.data.activityTags = this?.data?.activityTags?.length > 0 ? this.data.activityTags.map((at) => {
        return {
          ...at,
          active: this.$store.getters.getPftChartTagsVisible(at.name),
          total: this.data.map.reduce((acc, obj) => {
            let value = Number(obj[at.name])
            return isNaN(value) ? acc : acc + value
          }, 0)
        }
      }) : []
      const activeTags = this?.data?.activityTags?.length > 0 ? this.data.activityTags.filter((at) => at.active) : []
      if (this.data && activeTags.length > 0) {
        for (const activityTag of activeTags) {
          if (activityTag.name === 'average_profitability') {
            series.push({
              type: 'line',
              xKey: 'month',
              yKey: 'average_profitability',
              yName: 'Average Profitability',
              stroke: activityTag.color,
              strokeWidth: 4,
              marker: {
                fill: activityTag.color,
                size: 10
              },
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: `${SimpleHelper.monthYearStringToLabels(datum[xKey])}: ${datum[yKey]}%`,
                    title: yKey.replaceAll('_', ' ')
                  }
                }
              }
            });
          } else if (activityTag.name === 'active_projects') {
            series.push({
              type: 'line',
              xKey: 'month',
              yKey: 'active_projects',
              yName: 'Active Projects',
              stroke: activityTag.color,
              strokeWidth: 4,
              marker: {
                fill: activityTag.color,
                size: 10
              },
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: `${SimpleHelper.monthYearStringToLabels(datum[xKey])}: ${datum[yKey]}`,
                    title: yKey.replaceAll('_', ' ')
                  }
                }
              }
            });
          } else {
            series.push({
              type: 'line',
              xKey: 'month',
              yKey: activityTag.name,
              yName: activityTag.label,
              stroke: activityTag.color,
              strokeWidth: 4,
              marker: {
                fill: activityTag.color,
                size: 10
              },
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: `${SimpleHelper.monthYearStringToLabels(datum[xKey])}: ${SimpleHelper.priceFormatter(datum[yKey])}`,
                    title: yKey.replaceAll('_', ' ')
                  }
                }
              }
            })
          }
        }
        this.options = {
          height: 600,
          data: this.data.map,
          series: series,
          tooltip: {
            class: 'custom-tooltip'
          },
          legend: {
            enabled: false,
            // item: {
            //   label: {
            //     formatter: (params) => {
            //       let total = this.data.reduce((acc, obj) => acc + obj[params.itemId], 0)
            //       if (params.itemId === 'average_profitability') {
            //         return String(params.value + `  (${(total / this.data.length).toFixed(0)}%)`)
            //       } else if (params.itemId === 'active_projects') {
            //         return params.value
            //       } else {
            //         return params.value + ` (${SimpleHelper.priceFormatter(total)})`
            //       }
            //     }
            //   }
            // }
          },
          axes: [
            {
              type: 'category',
              position: 'bottom',
              label: {
                formatter: (params) => {
                  return SimpleHelper.monthYearStringToLabels(params.value)
                }
              }
            },
            {
              type: 'number',
              position: 'left',
              keys: ['total_revenue', 'total_expenses']
            },
            {
              type: 'number',
              position: 'right',
              keys: ['active_projects', 'average_profitability'],
              min: 0
            }
          ]
        }
      } else {
        this.options = JSON.parse(JSON.stringify({
          height: 600,
          data: [],
          series: []
        }))
      }
    },
    toggleActive(index) {
      this.$store.commit('setPftChartTagsVisible', { key: this.data.activityTags[index].name, value: !this.data.activityTags[index].active });
      this.$set(this.data.activityTags, index, {
        ...this.data.activityTags[index],
        active: !this.data.activityTags[index].active
      })
      this.setOptions()
    },
    toggleAll(active) {
      this.data.activityTags = this.data.activityTags.map((at) => {
        this.$store.commit('setPftChartTagsVisible', { key: at.name, value: active });
        return {
          ...at,
          active
        }
      })
      this.setOptions()
    }
  }
}
</script>
<style lang="scss" scoped>
.b-skeleton {
  border-radius: 0;
}
.charts {
  border: 1px solid #e6e6e6;
}
.buttons-group {
  display: flex;
  margin: 0px 25px 10px;
  gap: 10px;
}
.custom-legend {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0 20px 20px 20px;
}
.legend-item {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 5px;
}
.legend-item .label {
  font-size: 0.8rem;
}
.legend-item:hover {
  background-color: #f0f0f0;
}
.legend-item.active {
  opacity: 1;
}
.legend-item.inactive {
  opacity: 0.2;
}
.legend-item .color {
  display: block;
  height: 20px;
  width: 20px;
}
.hidden-checkbox {
  display: none;
}
</style>
