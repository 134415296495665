<template>
  <div class="projects-page">
    <div class="agGrid-page container">
      <base-header>
        <header-projects-charts-addon />
        <date-picker-addon v-show="showDatePicker" />
        <show-archived-addon />
        <refresh-analytics type="projects" />
      </base-header>
      <projects-charts-pft :data="chartsPftData" v-show="showChartsPft" />
      <projects-charts-exp :data="chartsExpData" v-show="showChartsExp" />
      <base-filters :filters="filters" />
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :statusBar="statusBar"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import BaseFilters from '@/agGridV2/components/filters/filters.component.vue'
import RefreshAnalytics from '@/agGridV2/components/header/refresh-analytics.component.vue'
import ProjectsChartsPft from '@/agGridV2/components/projects-charts-pft.component.vue'
import ProjectsChartsExp from '@/agGridV2/components/projects-charts-exp.component.vue'
import HeaderProjectsChartsAddon from '@/agGridV2/components/header/projects-charts.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
// Custom Cell Renderer
import ProjectsSelectRenderer from '@/agGridV2/renderers/projects-select.renderer.vue'
import SelectRenderer from '@/agGridV2/renderers/select.renderer.vue'
import SimpleSelectRenderer from '@/agGridV2/renderers/simple-select.renderer.vue'
import CheckboxRenderer from '@/agGridV2/renderers/checkbox.renderer.vue'
import ProfitRenderer from '@/agGridV2/renderers/profit.renderer.vue'
import ShowArchivedAddon from '@/agGridV2/components/header/show-archived.component.vue'
import DatePickerAddon from '@/agGridV2/components/header/date-picker.component.vue'
import DatePickRenderer from '@/agGridV2/renderers/date-pick.renderer'
import StatusBar from '@/agGridV2/renderers/status-bar.renderer.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    RefreshAnalytics,
    ShowArchivedAddon,
    DatePickerAddon,
    HeaderProjectsChartsAddon,
    BaseAgGrid,
    BaseHeader,
    // eslint-disable-next-line vue/no-unused-components
    ProjectsSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SimpleSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ProfitRenderer,
    ProjectsChartsPft,
    ProjectsChartsExp,
    // eslint-disable-next-line vue/no-unused-components
    DatePickRenderer,
    // eslint-disable-next-line vue/no-unused-components
    StatusBar,
    BaseFilters
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      chartsPftData: null,
      chartsExpData: null,
      statusBar: null,
      cellValueChanged: null,
      filters: []
    }
  },
  computed: {
    showChartsPft() {
      return this.getShowChartsPft()
    },
    showChartsExp() {
      return this.getShowChartsExp()
    },
    showDatePicker() {
      const projectType = this.$store.getters.getProjectType(this.$route.name)
      return projectType !== 'DEV'
    }
  },
  methods: {
    ...mapGetters([
      'getShowChartsPft',
      'getShowChartsExp',
      'getProjectType'
    ]),
    async init() {
      const [filters, filtersByCMS, filtersByPlatformUrl, filtersByIndustry, projects] = await Promise.all([
        MtApi.getProjectsAnalyticsFilters({
          field: 'type'
        }),
        MtApi.getProjectsAnalyticsFilters({
          field: 'cms'
        }),
        MtApi.getProjectsAnalyticsFilters({
          field: 'platform_url'
        }),
        MtApi.getProjectsAnalyticsFilters({
          field: 'industry'
        }),
        MtApi.getProjects()
      ])
      this.$emitter.on('header_reset_filters', () => {
        const timeout = setTimeout(() => {
          this.filters = [{
            type: 'simple_array',
            field: 'type',
            label: 'Type',
            selected: filters.filter((i) => !i.includes('-INT')),
            options: filters
          }]
          clearTimeout(timeout)
        }, 500)
      })
      filters.sort((a, b) => {
        const aContainsInt = a.includes('-INT')
        const bContainsInt = b.includes('-INT')
        if (aContainsInt === bContainsInt) {
          return 0
        }
        if (aContainsInt) {
          return 1
        }
        return -1
      })
      this.filters.push({
        type: 'simple_array',
        field: 'type',
        label: 'Type',
        selected: filters.filter((i) => !i.includes('-INT')),
        options: filters
      })
      const filteredProjects = projects.all.map((project) => project.abbr_l + '-' + project.type)
      this.statusBar = {
        statusPanels: [
          {
            statusPanel: 'StatusBar'
          }
        ]
      }
      const activity_tags = [
        'account_management',
        'project_management',
        'design',
        'development',
        'ppc_management',
        'discovery_and_setup',
        'reporting',
        'lead_qualification',
        'seo_strategy',
        'keywords_research',
        'service_specs',
        'blog_specs',
        'link_building',
        'content_management',
        'copywriting',
        'content_submission',
        'account_executive',
        'technical_and_qa'
      ]
      const total_expenses_by_tags = [
        {
          field: 'total.expenses.sum',
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['greaterThan', 'lessThan', 'inRange'],
            defaultOption: 'greaterThan'
          },
          headerName: 'Total ($)',
          valueFormatter: (params) => {
            return SimpleHelper.priceFormatter(params.value)
          }
        },
        {
          field: 'total.expenses.sum_by_hours',
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['greaterThan', 'lessThan', 'inRange'],
            defaultOption: 'greaterThan'
          },
          headerName: 'Total (H)',
          valueFormatter: (params) => {
            if (!params.value) {
              return ''
            }
            return params.value + 'h'
          },
        }
      ]
      for (const activity_tag of activity_tags) {
        total_expenses_by_tags.push({
          field: `total.expenses.by_activity_tag.${activity_tag}`,
          width: 300,
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['greaterThan', 'lessThan', 'inRange'],
            defaultOption: 'greaterThan'
          },
          headerName:
            activity_tag
              .replaceAll('_', ' ')
              .split(' ')
              .join(' ').toUpperCase() + ' ($)',
          valueFormatter: (params) => {
            return SimpleHelper.priceFormatter(params.value)
          },
          columnGroupShow: 'open'
        })
        total_expenses_by_tags.push({
          field: `total.expenses.by_activity_tag_hours.${activity_tag}`,
          width: 300,
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['greaterThan', 'lessThan', 'inRange'],
            defaultOption: 'greaterThan'
          },
          headerName:
            activity_tag
              .replaceAll('_', ' ')
              .split(' ')
              .join(' ').toUpperCase() + ' (H)',
          valueFormatter: (params) => {
            if (!params.value) {
              return ''
            }
            return params.value + 'h'
          },
          columnGroupShow: 'open'
        })
        if (activity_tag === 'technical_and_qa') {
          total_expenses_by_tags.push({
            field: `total.commissions.am`,
            width: 300,
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: ['greaterThan', 'lessThan', 'inRange'],
              defaultOption: 'greaterThan'
            },
            headerName: 'AM COMMISSION',
            valueFormatter: (params) => {
              return SimpleHelper.priceFormatter(params.value)
            },
            columnGroupShow: 'open'
          })
        }
      }
      this.columnDefs = [
        {
          field: 'name',
          headerName: 'Project',
          pinned: 'left',
          cellRenderer: function (params) {
            if (!params.value) return ''
            const status = params.data.status
            let intClass = ' '
            if (params.data.type.includes('-INT')) {
              intClass = ' project_int '
            }
            let html = `<div class="project_status${intClass}project_status--${status}">${params.value}</div>`
            if (params.data.gocardless) {
              html += '<div class="gocardless"></div>'
            }
            return html
          },
          filter: 'agSetColumnFilter',
          filterParams: {
            values: (params) => {
              params.success(filteredProjects)
            }
          }
        },
        {
          field: 'cms',
          headerName: 'CMS',
          pinned: 'left',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: (params) => {
              params.success(filtersByCMS)
            }
          }
        },
        {
          field: 'platform_url',
          headerName: 'Platform',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: (params) => {
              params.success(filtersByPlatformUrl)
            }
          },
          cellRenderer: (params) => {
            return `<a href="https://${params.value}" target="_blank">${params.value}</a>`
          },
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          },
        },
        {
          field: 'type',
          headerName: 'Type',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: (params) => {
              params.success(filters)
            }
          }
        },
        {
          field: 'client_name',
          headerName: 'Client Name (QB)',
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains', 'notContains'],
            defaultOption: 'contains'
          }
        },
        {
          field: 'industry',
          headerName: 'Industry',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: (params) => {
              params.success(filtersByIndustry)
            }
          },
          cellRenderer: (params) => {
            return params.value ? SimpleHelper.getIndustryLabel(params.value) : 'Not set'
          }
        },
        {
          field: 'total.profit',
          headerName: 'Total Profit',
          cellRenderer: 'ProfitRenderer',
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['greaterThan', 'lessThan', 'inRange'],
            defaultOption: 'greaterThan'
          }
        },
        {
          field: 'total.revenue.sum',
          headerName: 'Total Revenue',
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['greaterThan', 'lessThan', 'inRange'],
            defaultOption: 'greaterThan'
          },
          cellRenderer: (params) => {
            let string = SimpleHelper.priceFormatter(params.value)
            if (params.data.total.revenue.sum_unpaid) {
              string += '<div style="color: red; display: inline"> (' + SimpleHelper.priceFormatter(params.data.total.revenue.sum_unpaid) + ')</div>'
            }
            return string
          }
        },
        {
          field: 'budget',
          headerName: 'Total Budget',
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['greaterThan', 'lessThan', 'inRange'],
            defaultOption: 'greaterThan'
          },
          valueFormatter: (params) => {
            return SimpleHelper.priceFormatter(params.value)
          },
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          },
        },
        {
          headerName: 'Total Expenses',
          children: total_expenses_by_tags
        },
        {
          field: 'kick_off_date',
          headerName: 'Kick off Date',
          filter: 'agDateColumnFilter',
          filterParams: {
            filterOptions: ['inRange']
          },
          cellRenderer: 'DatePickRenderer',
          cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 },
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          }
        },
        {
          field: 'real_launch_date',
          headerName: 'Launch Date',
          filter: 'agDateColumnFilter',
          filterParams: {
            filterOptions: ['inRange']
          },
          cellRenderer: 'DatePickRenderer',
          cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 },
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          }
        },
        {
          field: 'cancellation_date',
          headerName: 'Cancellation Date',
          filter: 'agDateColumnFilter',
          filterParams: {
            filterOptions: ['inRange']
          },
          cellRenderer: 'DatePickRenderer',
          cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 },
          cellClassRules: {
            'not-editable': (params) => {
              return params.value === 'inapplicable'
            }
          }
        }
      ]
      this.datasource = {
        getRows: (params) => {
          params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
          params.request['showArchived'] = this.$store.getters.getShowArchived(this.$route.name)
          params.request['projectFromDate'] = this.$store.state.managementTools.selectedDateFrom
          params.request['projectToDate'] = this.$store.state.managementTools.selectedDateTo
          this.chartsPftData = []
          this.chartsExpData = {}
          MtApi.agGridProjectsAnalytics(params.request).then((response) => {
            this.chartsPftData = response.chartsPft
            this.chartsExpData = response.chartsExp
            this.$emitter.emit('status_bar_update', {
              count: response.count
            })
            params.success({ rowData: response.rows, rowCount: response.count })
          })
        }
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>
